import React, { useEffect } from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { PortableText } from "@portabletext/react";
import PortableContent from "./PortableContent";
import TableContents from "./TableContents";
import ScrollProgress from "./ScrollProgress";

let options = {
  root: null,
  rootMargin: "0% 0% -50% 0%",
  threshold: 0,
};

const ArticleB = ({ article }) => {
  let previous = "";
  // let counter = 0;

  useEffect(() => {
    const sections = Array.from(
      document.querySelectorAll("h1, h2, h3, h4, h5")
    );

    const scrollHandler = (entries) => {
      return entries.forEach((entry) => {
        const section = entry.target;
        const sectionId = section?.id;

        const sectionLink = document.querySelector(`a[href="#${sectionId}"]`);
        const previousLink = document.querySelector(`a[href="#${previous}"]`);

        // const index = sections.findIndex((i) => i.id === sectionId);

        if (entry.isIntersecting) {
          sectionLink?.classList.add("item-visible");

          // if (counter < 2) {
          //   counter += 1;
          // }

          if (previous !== sectionId) {
            previousLink?.classList.remove("item-visible");
          }
          previous = sectionId;
        } else {
          // previous = sectionId;

          // if (counter > 0 && counter <= 1) {
          //   counter += 1;
          // }

          // if (counter === 2) {
          //   counter = 0;
          // }

          if (previous !== sectionId) {
            sectionLink?.classList.remove("item-visible");
          }
          // if (index === sections.length) {
          //   console.log("===max", index);
          //   previous = "";
          // }
        }
      });
    };
    // Creates a new scroll observer
    const observer = new IntersectionObserver(scrollHandler, options);

    sections.forEach((section) => observer.observe(section));
  }, []);

  return (
    <div className="flex max-w-[1150px] mx-auto">
      {article?.tableContent && <TableContents data={article?.tableContent} />}
      <ScrollProgress />
      <div className="w-full mt-[77px] bg-black/[.02] rounded-[33px] py-8 lg:py-[59px] mb-[50px] article">
        <div className="px-4 lg:px-8 article-content">
          <div className="lg:px-[37px]">
            <div className="flex flex-wrap text-sm font-lora">
              <div>
                <Link
                  className="text-[#AF872D] hover:underline"
                  to={`/blog/${article.category[0]?.slug?.current}`}
                >
                  {article.category[0]?.title}
                </Link>
              </div>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <div>
                Reading Time: {article?.readTime ? article?.readTime : 5}{" "}
                minutes
              </div>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <div>Last Updated On: {article?._updatedAt}</div>
            </div>
            <div>
              <h1 className="text-4xl leading-[46px] mt-6 text-black">
                {article?.heading}
              </h1>
            </div>
            {article?.summary && (
              <div className="mt-4">
                <p className="text-sm font-lora opacity-70 article-summary">
                  {article?.summary}
                </p>
              </div>
            )}
            <div className="mt-[49px] mb-[50px]">
              <GatsbyImage
                className="w-full rounded-[14px]"
                image={article?.featuredImage?.asset?.gatsbyImageData}
                imgClassName="w-full"
                alt={article?.heading}
              />
              {article?._rawFeaturedImageattribution && (
                <div className="prose max-w-none">
                  <div className="mt-2 mb-4 text-sm leading-6 text-[#676a6b] text-center attribution">
                    <PortableText
                      value={article?._rawFeaturedImageattribution}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={"article-body"}>
              <div className="text-base leading-5 prose max-w-none article-b">
                <PortableContent article={article} />
              </div>
            </div>
            <div className="mt-20">
              <div id="disqus_thread"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleB;
